import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7424790"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "sentence-case" }
const _hoisted_4 = {
  key: 1,
  class: "ion-text-center ion-padding-top"
}
const _hoisted_5 = {
  key: 2,
  class: "ion-text-center ion-padding-top"
}
const _hoisted_6 = {
  key: 1,
  class: "ion-text-center ion-padding-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_Image = _resolveComponent("Image")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            (Object.keys(_ctx.order).length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_ion_item, {
                    class: "ion-text-center",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("Your Order")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        class: "border"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order.customerName) + " ", 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.order.id), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_note, { slot: "end" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$filters.formatDate(_ctx.order.orderDate)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.order.statusId !== 'ORDER_CANCELLED' && _ctx.order.shipGroup.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.shipGroup, (shipGroup, index) => {
                          return (_openBlock(), _createBlock(_component_ion_card, { key: index }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shipGroup.items, (item) => {
                                return _withDirectives((_openBlock(), _createBlock(_component_ion_item, {
                                  key: item.id,
                                  lines: "full"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_Image, {
                                          src: _ctx.getProduct(item.productId).mainImageUrl
                                        }, null, 8, ["src"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_label, { slot: "start" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(item.brandName), 1),
                                        _createElementVNode("h2", null, _toDisplayString(item.name), 1),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.$filters.groupFeatures(_ctx.getProduct(item.productId).featureHierarchy)), (attribute, feature) => {
                                          return (_openBlock(), _createElementBlock("p", { key: attribute }, [
                                            _createElementVNode("span", _hoisted_3, _toDisplayString(feature), 1),
                                            _createTextVNode(": " + _toDisplayString(attribute), 1)
                                          ]))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)), [
                                  [_vShow, item.status !== 'ITEM_CANCELLED']
                                ])
                              }), 128)),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_select, {
                                    label: _ctx.$t('Delivery method'),
                                    disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHPGRP_DLVRMTHD_UPDATE),
                                    interface: "popover",
                                    value: shipGroup.selectedShipmentMethodTypeId,
                                    onIonChange: ($event: any) => (_ctx.updateDeliveryMethod($event, shipGroup))
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deliveryMethods, (method) => {
                                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                                          key: method.value,
                                          value: method.value
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(method.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1032, ["label", "disabled", "value", "onIonChange"])
                                ]),
                                _: 2
                              }, 1024),
                              (shipGroup.shipmentMethodTypeId === 'STOREPICKUP' && shipGroup.selectedShipmentMethodTypeId !== shipGroup.shipmentMethodTypeId && !shipGroup.updatedAddress)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 0,
                                    disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHPGRP_DLVRADR_UPDATE) && shipGroup.shipmentMethodTypeId !== 'STOREPICKUP',
                                    onClick: ($event: any) => (_ctx.updateDeliveryAddress(shipGroup)),
                                    expand: "block",
                                    fill: "outline"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("Add address")), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["disabled", "onClick"]))
                                : (shipGroup.selectedShipmentMethodTypeId === 'STOREPICKUP' && !shipGroup.selectedFacility)
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 1,
                                      disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHPGRP_PCKUP_UPDATE) && shipGroup.shipmentMethodTypeId === 'STOREPICKUP',
                                      onClick: ($event: any) => (_ctx.updatePickupLocation(shipGroup)),
                                      expand: "block",
                                      fill: "outline",
                                      class: "ion-padding"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("Select pickup location")), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["disabled", "onClick"]))
                                  : (shipGroup.selectedShipmentMethodTypeId === 'STOREPICKUP')
                                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_list, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(shipGroup.selectedFacility.facilityName), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_label, { color: "dark" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(shipGroup.selectedFacility.address1), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_label, { color: "dark" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(shipGroup.selectedFacility.city) + " " + _toDisplayString(shipGroup.selectedFacility.stateCode) + " " + _toDisplayString(shipGroup.shipTo.postalAddress.country) + " " + _toDisplayString(shipGroup.selectedFacility.postalCode), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_ion_button, {
                                            disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHPGRP_PCKUP_UPDATE) && shipGroup.shipmentMethodTypeId === 'STOREPICKUP',
                                            slot: "end",
                                            onClick: ($event: any) => (_ctx.updatePickupLocation(shipGroup)),
                                            color: "medium",
                                            fill: "outline"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$t("Change Store")), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["disabled", "onClick"])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : (_openBlock(), _createBlock(_component_ion_item, { key: 3 }, {
                                        default: _withCtx(() => [
                                          (shipGroup.updatedAddress)
                                            ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_label, null, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(shipGroup.updatedAddress.firstName) + " " + _toDisplayString(shipGroup.updatedAddress.lastName), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_ion_label, { color: "dark" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(shipGroup.updatedAddress.address1), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_ion_label, { color: "dark" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(shipGroup.updatedAddress.city) + " " + _toDisplayString(shipGroup.updatedAddress.stateCode) + " " + _toDisplayString(shipGroup.updatedAddress.postalCode), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            : (shipGroup.shipmentMethodTypeId !== 'STOREPICKUP')
                                              ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_label, null, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(shipGroup.shipTo.postalAddress.toName), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_ion_label, { color: "dark" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(shipGroup.shipTo.postalAddress.address1), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_ion_label, { color: "dark" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(shipGroup.shipTo.postalAddress.city) + " " + _toDisplayString(shipGroup.shipTo.postalAddress.stateCode) + " " + _toDisplayString(shipGroup.shipTo.postalAddress.postalCode), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              : _createCommentVNode("", true),
                                          (shipGroup.shipmentMethodTypeId !== 'STOREPICKUP' || shipGroup.updatedAddress)
                                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 2,
                                                disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHPGRP_DLVRADR_UPDATE) && shipGroup.shipmentMethodTypeId !== 'STOREPICKUP',
                                                slot: "end",
                                                onClick: ($event: any) => (_ctx.updateDeliveryAddress(shipGroup)),
                                                color: "medium",
                                                fill: "outline"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t("Edit address")), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["disabled", "onClick"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024)),
                              (shipGroup.trackingNumber)
                                ? (_openBlock(), _createBlock(_component_ion_item, { key: 4 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('Tracking code')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_note, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(shipGroup.trackingNumber), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true),
                              _createVNode(_component_ion_button, {
                                disabled: (!shipGroup.updatedAddress && (!shipGroup.selectedFacility || shipGroup.selectedFacility.facilityId == shipGroup.facilityId)),
                                onClick: ($event: any) => (_ctx.save(shipGroup)),
                                fill: "clear"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("Save changes")), 1)
                                ]),
                                _: 2
                              }, 1032, ["disabled", "onClick"]),
                              (_ctx.hasPermission(_ctx.Actions.APP_SHPGRP_CNCL))
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 5,
                                    onClick: ($event: any) => (_ctx.cancel(shipGroup)),
                                    fill: "clear",
                                    color: "danger"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("Cancel")), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]))
                    : (_ctx.isOrderUpdated)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("An email will be sent to you when your item(s) are ready to collect at the new requested location(s).")), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("Order item not eligible for reroute fulfilment")), 1)
                            ]),
                            _: 1
                          })
                        ]))
                ]))
              : (_ctx.loader == null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("Order not found")), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}